<template>
  <div class="d-flex align-center" :class="{'absolute': absolute}">
    <v-menu offset-y v-if="separateRegionMenu && currentScrapingRegion">
      <template v-slot:activator="{ props }">
        <div class="d-flex flex-column align-center mt-1" v-bind="props">
          <img
            :src="`https://flagcdn.com/h40/${lowerCaseCountryCode}.png`"
            :srcset="`https://flagcdn.com/h80/${lowerCaseCountryCode}.png 2x`"
            height="16"
            :alt="currentScrapingRegion.name">
          <div class="region-name">{{currentScrapingRegion.name}}</div>
        </div>
      </template>

      <v-card rounded="0" color="primary">
        <v-list>
          <v-list-item>
            <v-list-item-title class="d-flex justify-start py-2">
              <div class="d-flex flex-column align-start">
                <div class="ml-4 text-caption" v-if="currentScrapingRegion">{{currentScrapingRegion.name}}</div>
                <v-btn @click.prevent="changeLocation" variant="text">
                  <v-icon>mdi-map</v-icon>
                  <span>Change Region</span>
                </v-btn>
                <div class="flag-attribution-text">
                  Flags from https://flagpedia.net
                </div>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-menu offset-y>
      <template v-slot:activator="{ props }">
        <v-list-item>
          <v-avatar
            class="app-bar-avatar"
            :color="!photoUrl ? 'primary' : 'white'"
            v-bind="props"
            :size="32"
          >
            <v-progress-circular
              indeterminate
              color="white"
              :size="20"
              :width="3"
              v-if="loadingPrefs"
            ></v-progress-circular>
            <v-img v-else-if="photoUrl" src="photoUrl"></v-img>
            <span v-else class="text-white text-subtitle-2">{{ userInitials }}</span>
          </v-avatar>
        </v-list-item>
      </template>

      <v-card rounded="0" color="primary">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{displayName || userName || email}}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item v-if="!separateRegionMenu">
            <v-list-item-title class="d-flex justify-start">
              <div class="d-flex flex-column align-start">
                <div class="ml-4 text-caption d-flex align-center" v-if="currentScrapingRegion">
                  <img
                    :src="`https://flagcdn.com/h40/${lowerCaseCountryCode}.png`"
                    :srcset="`https://flagcdn.com/h80/${lowerCaseCountryCode}.png 2x`"
                    height="16"
                    :alt="currentScrapingRegion.name">
                  <div class="ml-2">{{currentScrapingRegion.name}}</div>
                </div>
                <v-btn @click.prevent="changeLocation" variant="text">
                  <v-icon>mdi-map</v-icon>
                  <span>Edit Default Region</span>
                </v-btn>
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="showBoards">
            <v-list-item-title class="d-flex justify-start">
              <v-btn to="/pikles" variant="text">
                <v-icon>mdi-star-outline</v-icon>
                <span class="ml-2">Your Boards</span>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="d-flex justify-start">
              <v-btn href="#" @click.prevent="logout" variant="text">
                <v-icon>mdi-logout</v-icon>
                <span class="ml-2">Logout</span>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

  </div>
</template>

<script>

import AuthUtil from '../../authUtil'

import { signOut } from "firebase/auth";

import { mapState } from 'vuex'

// Mixins
import resetStore from '@/mixins/ResetStore.js'

// Veux types
import * as UserTypes from '@/store/types/user'

export default {
  name: 'UserMenu',
  mixins: [resetStore],
  data () {
    return {
    }
  },
  props: {
    absolute: {
      type: Boolean,
      default: false
    },
    separateRegionMenu: {
      type: Boolean,
      default: false
    },
    showBoards: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      hideIntroPreference: state => state.user.hideIntro,
      userName: state => state.user.userName,
      countryCode: state => state.user.currentCountry,
      scrapingRegions: state => state.user.scrapingRegions,
      regionCode: state => state.user.currentRegion,
      // userGeoPosition: state => state.user.currentGeoPosition,
      loadingPrefs: state => state.user.loading,
      prefsLoaded: state => state.user.loaded,
      showExperimentalFeatures: state => state.user.showExperimentalFeatures,
      userMetaData: state => state.user.metaData
    }),
    currentScrapingRegion () {
      return this.scrapingRegions.find(next => next.country === this.countryCode)
    },
    lowerCaseCountryCode () {
      return this.countryCode.toLowerCase()
    },
    displayName () {
      return this.userMetaData ? this.userMetaData.displayName : null
    },
    email () {
      return this.userMetaData ? this.userMetaData.email : null
    },
    photoUrl () {
      return this.userMetaData ? this.userMetaData.photoUrl : null
    },
    showIntro: {
      // getter
      get: function () {
        return !this.hideIntroPreference
      },
      // setter
      set: function (newValue) {
        this.$store.dispatch(`user/${UserTypes.ACTION_SET_HIDE_INTRO}`, !newValue)
      }
    },
    userInitials () {
      let firstChar = "?"
      let secondChar = ""

      const theName = this.displayName || this.userName || null
      if (theName) {
        firstChar = theName.charAt(0)
        const displayNameWords = theName.split(' ')
        if (displayNameWords && displayNameWords.length > 1) {
          secondChar = displayNameWords[1].charAt(0)
        }
      }
      else if (this.email) {
        const emailName = this.email.split('@')[0]
        firstChar = emailName.charAt(0)
        const emailWords = emailName.split('.')
        if (emailWords && emailWords.length > 1) {
          secondChar = emailWords[1].charAt(0)
        }
      }

      return `${firstChar}${secondChar}`.toUpperCase()
    }
  },
  methods: {
    logout: function () {
      this.resetAllStoreModules()
      signOut(AuthUtil.auth).then(() => {
        this.$router.replace('/signin')
        window.location.reload(true) // Just to make sure everything is reset
      })
    },
    changeLocation: function () {
      this.$router.push('/location')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.absolute {
  position: absolute;
  top: 0px;
  right: 16px;
}
.app-bar-avatar {
  margin-right: 0px !important;
  cursor: default;
}
.region-name {
  font-size: 0.5rem;
  color: #999;
}
.flag-attribution-text {
  font-size: 0.5rem;
  color: #999;
}
// .user-menu {
//   background-color: #0e2887 !important;
//
//   .v-list {
//     background: none;
//   }
// }
</style>
