// import Vue from 'vue'
// import Vuex from 'vuex'
import decisions from './modules/decisions'
// import decision from './modules/decision'
import pikleBoard from './modules/pikleBoard'
import user from './modules/user'

// // Use Vuex
// Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'

// export default new Vuex.Store({
//   modules: {
//     user,
//     decisions,
//     pikleBoard
//     // decision
//   },
//   strict: debug
// })

import { createStore } from 'vuex'

export const store = createStore({
  state () {
    return {
      count: 1
    }
  },
  modules: {
    user,
    decisions,
    pikleBoard
  }
})
