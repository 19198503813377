<template>
  <v-bottom-navigation
    :name="'app-bottom-nav'"
    app
  >
    <v-btn
      class="app-bar-button"
      v-for="item in navList"
      :key="item.icon"
      :to="item.requiresRegion ? regionalPath(item.path) : item.path"
      text
    >
      <span>{{ $t(`${item.i18n}`) }}</span>
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'AppBottomBar',
    props: {
      navList: Array
    },
    computed: {
      ...mapState({
        navigatingToDecision: state => state.pikleBoard.navigating,
        currentCountry: state => state.user.currentCountry
      })
    },
    methods: {
      regionalPath: function (basePath) {
        if (this.currentCountry) {
          return `/${this.currentCountry.toLowerCase()}${basePath}`
        } else {
          return basePath
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.app-bar-button {
  text-transform: capitalize;
  margin-top: 1px;
  color: #222;
  font-weight: 400;
  font-size: 0.8rem;

  &.v-btn--active {
    color: #000;

    .v-icon {
      color: #d900ff;
    }
  }
}
</style>
