import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics";
import { onAuthStateChanged } from "firebase/auth";

// Import Vue dependencies
// import Vue from 'vue'
import {createApp} from 'vue'
import { createI18n } from 'vue-i18n'
// import VueI18n from 'vue-i18n'
import App from './App.vue'
import { store } from './store'
import router from './router'
import vuetify from '@/plugins/vuetify'

import './axiosConfig'

import FirestoreUtil from './firestoreUtil'
import AuthUtil from './authUtil'
import AnalyticsUtil from './analyticsUtil'

// import userAPI from './api/user'

// Importing the global css file
import "@/styles/app.scss"
// import '@/styles/variables.scss'

// NOTE: These are OK to have here and are considered public.
// See https://firebase.google.com/docs/projects/learn-more#config-files-objects
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}

// Optional measurement id
if (process.env.VUE_APP_FIREBASE_MEASUREMENT_ID) {
  firebaseConfig.measurementId = process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
FirestoreUtil.init(firebaseApp)
AuthUtil.init(firebaseApp)
AnalyticsUtil.init(firebaseApp)

// Vue.use(VueI18n)
// Vue.config.productionTip = false

// Load all locales and remember context
function loadMessages() {
  const context = require.context("./locales", true, /[a-z0-9-_]+\.json$/i);

  const messages = context
    .keys()
    .map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
    .reduce(
      (messages, { key, locale }) => ({
        ...messages,
        [locale]: context(key),
      }),
      {}
    );

  return { messages };
}

const { messages } = loadMessages();

// VueI18n instance
const i18n = createI18n({
  locale: "en",
  messages
})
// const i18n = new VueI18n({
//   locale: "en",
//   messages,
// });

let app = ''


// Initialize Analytics and get a reference to the service
if (process.env.VUE_APP_FIREBASE_MEASUREMENT_ID) {
  getAnalytics(firebaseApp);
}


const unsubscribe = onAuthStateChanged(AuthUtil.auth, () => {
  if (!app) {
    unsubscribe();
    
    app = createApp(App).use(router).use(store).use(i18n).use(vuetify).mount('#app')
    // app = new Vue({
    //   router,
    //   store,
    //   i18n,
    //   vuetify,
    //   render: h => h(App)
    // }).$mount('#app')
  }

  // Record auth state change in usage stats
  // Stop this nonsense
  // if (AuthUtil.auth.currentUser) {
  //   userAPI.recordUsageStats(AuthUtil.auth.currentUser.uid, 'authStateChangedUserActive')
  // }
})
