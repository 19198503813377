// import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
// import light from './vuetify/app-theme'

// Vue.use(Vuetify);

// export default new Vuetify({
//   theme: {
//     options: {
//       customProperties: true,
//     },
//     themes: { light }
//   },
// });

import 'vuetify/styles'
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: `#00b7fc`,
          secondary: `#c44bc4`,
          accent: `#ff9800`,
          error: `#f44336`,
          warning: `#ffc107`,
          info: `#607d8b`,
          success: `#4caf50`
        },
      },
      dark: {
        colors: {
          primary: '#2196F3',
          secondary: '#424242',
          accent: '#FF4081',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
      },
    },
  }
})